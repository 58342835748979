<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/config')"
          >
          <span class="mapinfo-title">&nbsp;&nbsp;&nbsp;지도 변경 및 관리</span>
        </v-row>
        <v-row
          class="map-area"
          justify="center"
        >
          <v-col>
            <ul class="mt-3">
              <li
                v-for="(item, i) in list"
                :key="i"
                class="btn-container mt-5"
              >
                <v-row
                  class="btn"
                >
                  <v-col
                    v-if="item.map_id !== now_map_id"
                    class="btn-col pa-0"
                    cols="10"
                    type="button"
                    @click="onClickMap(item.map_id)"
                  >
                    <span>{{ item.map_name }}</span>
                  </v-col>
                  <v-col
                    v-if="item.map_id === now_map_id"
                    class="btn-col pa-0"
                    cols="10"
                  >
                    <span>{{ item.map_name }}</span>
                  </v-col>
                  <v-col
                    v-if="item.map_id !== now_map_id&& false"
                    class="btn-col pa-0"
                    cols="1"
                    type="button"
                    @click="toModifyMap(item.map_id)"
                  >
                    <img src="@/assets/setting.png">
                  </v-col>
                  <v-col
                    v-if="item.map_id !== now_map_id"
                    class="btn-col pa-0"
                    cols="1"
                    type="button"
                    @click="onClickRemoveMap(item.map_id)"
                  >
                    <img src="@/assets/remove.png">
                  </v-col>
                </v-row>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import DefaultHeader from '@/components/header/defaultHeader.vue';
export default {
  name: 'MapInfo',
  components: { DefaultModal, DefaultHeader },
  data () {
    return {
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
      list: [],
      map_id: null,
      now_map_id: null,
    };
  },
  created () {
    this.initialize();
  },
  methods: {
    initialize() {
      return this.getMapList()
        .then(this.getCurrentMap)
        .catch(err => { throw Error(err); });
    },
    getMapList() {
      return this.$axios.get('/map/list')
        .then(res => {
          if (res.status === 200) {
            this.list = res.data;
          }
        });
    },
    getCurrentMap() {
      return this.$axios.get('/info')
        .then(res => {
          if (res.status === 200) {
            this.now_map_id = res.data.map_id;
          }
        });
    },
    onClickMap(id) {
      this.map_id = id;
      this.modalState.message = '지도를 변경하시겠습니까?';
      this.modalState.action = '변경';
      this.showModal = true;
    },
    onClickRemoveMap(id) {
      this.map_id = id;
      this.modalState.message = '지도를 삭제하시겠습니까?';
      this.modalState.action = '삭제';
      this.showModal = true;
    },
    doAction() {
      if (this.modalState.action === '변경') {
        this.modalState.message = '지도를 변경중입니다';
        this.modalState.action = 'no-action';
        this.changeMap();
      } else {
        this.removeMap();
      }
    },
    changeMap() {
      return this.$axios.put(`/mode/change/nav/${this.map_id}`, {
        mapid: this.map_id
      })
        .then(res => {
          if (res.status === 200) {
            this.initialize();
          }
          this.closeModal();
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
    removeMap() {
      return this.$axios.delete(`/map/${this.map_id}`, {
          mapid: this.map_id
      })
        .then(res => {
          if (res.status === 200) {
            this.initialize();
          }
          this.closeModal();
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    },
    toModifyMap(mapId) {
      this.$router.replace({
        name: 'MapEdit',
        params: {
          mapId: mapId
        }
      });
    },
  }
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  height: 90vh;
}

.container {
  height: 100vh;
}

.mapinfo-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
}

.map-area {
  height: 70vh;
  overflow: auto;
}

.btn-container {
  list-style: none;
  width: 100%;
}

.btn-container > .btn {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  background-color: #00BFB4;
  border-radius: 15px;
  text-align: center;
}

.btn-col {
  align-self: center;
}

.btn-container > .btn  span {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  color: #ffffff;
}

.btn-col > img {
  width: 50px;
  height: 50px;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .container > .header > .mapinfo-title {
    font-size: 20px !important;
  }

  .btn-container > .btn{
    width: 100%;
    border-radius: 10px;
  }

  .btn-container > .btn  span {
    height: 50px !important;
    font-size: 20px !important;
  }

  .btn-container > .btn > .col > img {
    width: 15px;
    height: 15px;
  }
}

</style>
